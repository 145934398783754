import React from "react";
import { graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import styled from "styled-components";
import Layout from "../../components/layout";
import SEO from "../../components/seo";
import {
  innerWidth,
  mainCyan,
  lightPurple,
  screen,
} from "../../components/variables";
import {
  IconCircleCyanLg,
  IconCircleCyanSm,
  IconCircleLightPurpleMd,
} from "../../components/circles";
import FaqRow from "../../components/faq-row";

const Wrapper = styled.div`
  position: relative;
  overflow: hidden;

  .inner-wrapper {
    max-width: ${innerWidth};
    margin: 0 auto;
    padding: 0 32px;
    @media ${screen.xxsmall} {
      padding: 0 42px;
    }
    @media ${screen.medium} {
      padding: 0;
    }

    &--header {
      display: flex;
      flex-direction: column;
      @media ${screen.xsmall} {
        flex-direction: row;
      }
    }
  }

  .header {
    margin: 0 0 50px 0;
    @media ${screen.small} {
      margin: 0;
    }

    .col {
      &--img {
        width: 100%;
        order: 2;
        position: relative;
        max-width: 286px;
        margin: 26px auto 0 auto;
        @media ${screen.xxsmall} {
          max-width: 580px;
          margin: 32px auto 0 auto;
        }
        @media ${screen.xsmall} {
          order: 0;
          width: 50%;
          max-width: none;
          padding: 0 70px 0 0;
          margin: 0;
        }

        .icon-circle {
          width: 65px;
          left: -10px;
          bottom: 14px;
          @media ${screen.xxsmall} {
            width: 144px;
            left: -40px;
            bottom: 40px;
          }
        }
      }

      &--text {
        @media ${screen.xsmall} {
          width: 50%;
        }

        .page-label {
          font-size: 0.93rem;
          letter-spacing: 2px;
          font-weight: 900;
          margin: 0 0 11px 0;
          display: block;
          @media ${screen.xxsmall} {
            font-size: 1.1rem;
            margin: 0 0 8px 0;
          }
        }

        .heading {
          font-size: 5rem;
          font-weight: 900;
          line-height: 1.22;
          max-width: 450px;
          position: relative;
          font-size: 2rem;
          @media ${screen.xxsmall} {
            font-size: 3.8rem;
          }
          @media ${screen.small} {
            font-size: 5rem;
            line-height: 1.09;
          }
        }

        .description {
          margin: 23px 0 38px auto;
          max-width: 590px;
          display: none;
          @media ${screen.xsmall} {
            display: block;
          }

          p {
            margin: 0 0 21px 0;
            font-size: 1.02rem;
            @media ${screen.small} {
              font-size: 1.06rem;
            }
            &:last-child {
              margin: 0;
            }
          }

          strong {
            font-weight: 700;
          }

          a {
            color: ${mainCyan};
            text-decoration: underline;
            @media ${screen.withCursor} {
              &:hover {
                color: ${lightPurple};
              }
            }
          }
        }
      }

      &--mobile-only {
        display: block;
        order: 4;
        @media ${screen.xxsmall} {
          padding: 34px 0;
        }
        @media ${screen.xsmall} {
          display: none;
        }

        .description {
          display: block;
          max-width: none;
        }
      }
    }
  }

  .section {
    padding: 0 0 60px 0;
    position: relative;
    overflow: hidden;
    @media ${screen.xxsmall} {
      padding: 0 0 110px 0;
    }

    .section-list {
      &__each {
        display: flex;
        flex-direction: column;
        margin: 0 0 25px 0;
        @media ${screen.xxsmall} {
          align-items: center;
          flex-direction: row;
          margin: 0 0 50px 0;
        }

        .col {
          @media ${screen.xxsmall} {
            width: 50%;
          }

          &--text {
            padding: 0 50px 0 0;
            order: 2;
            @media ${screen.xxsmall} {
              order: 0;
            }

            .heading {
              font-weight: 700;
              line-height: 1.4;
              margin: 26px 0 14px 0;
              font-size: 1.25rem;
              @media ${screen.xxsmall} {
                font-size: 1.52rem;
                margin: 0 0 23px 0;
              }

              @media ${screen.small} {
                font-size: 1.55rem;
              }
            }

            .description {
              max-width: 550px;

              p {
                margin: 0 0 21px 0;
                font-size: 1.02rem;
                @media ${screen.small} {
                  font-size: 1.06rem;
                }
                &:last-child {
                  margin: 0;
                }
              }

              strong {
                font-weight: 700;
              }

              a {
                color: ${mainCyan};
                text-decoration: underline;
                @media ${screen.withCursor} {
                  &:hover {
                    color: ${lightPurple};
                  }
                }
              }
            }
          }

          &--img {
          }
        }
      }
    }

    .icon-circle {
      display: none;
      @media ${screen.xxsmall} {
        display: block;
        bottom: 85px;
        width: 100px;
        left: -25px;
      }
    }
  }

  .icon-circle {
    &--cyan {
      display: none;
      @media ${screen.medium} {
        display: block;
        width: 250px;
        right: -120px;
        top: 500px;
      }
    }
  }
`;

const WellnessCentrePage = ({ data }) => {
  const {
    banner_heading,
    banner_description,
    banner_image_large,
    section_list,
    title_tag,
    meta_description,
  } = data.content.data;

  const pageURL = typeof window !== "undefined" && window.location.href;

  return (
    <Layout noCirclesOnMobileFooter>
      <SEO
        title={title_tag || "Wellness Centre"}
        description={meta_description}
        image={banner_image_large.thumbnails.cropped.url}
        url={pageURL}
        webPageInfo={{
          name: title_tag || "Wellness Centre",
          url: pageURL,
          description: meta_description,
        }}
      />
      <Wrapper>
        <header className="header">
          <div className="inner-wrapper inner-wrapper--header">
            <div className="col col--img">
              <GatsbyImage
                image={banner_image_large.gatsbyImageData}
                alt={banner_image_large.alt || "Featured"}
              />

              <IconCircleCyanLg className="icon-circle" />
            </div>
            <div className="col col--text">
              <span className="page-label">SERVICES</span>
              <h1 className="heading">{banner_heading.text}</h1>

              <div
                className="description"
                dangerouslySetInnerHTML={{ __html: banner_description.html }}
              />
            </div>
            <div className="col col--text col--mobile-only">
              <div
                className="description"
                dangerouslySetInnerHTML={{ __html: banner_description.html }}
              />
            </div>
          </div>
        </header>

        <section className="section" data-sal="fade" data-sal-duration="700">
          <ul className="inner-wrapper section-list">
            {section_list.map((item) => (
              <li key={item.heading.text} className="section-list__each">
                <div className="col col--text">
                  <h2 className="heading">{item.heading.text}</h2>
                  <div
                    className="description"
                    dangerouslySetInnerHTML={{ __html: item.description.html }}
                  />
                </div>

                <div className="col col--img">
                  <GatsbyImage
                    image={
                      item.featured_image.thumbnails.cropped.gatsbyImageData
                    }
                    alt={item.featured_image.alt || "Featured"}
                  />
                </div>
              </li>
            ))}
          </ul>

          <IconCircleLightPurpleMd className="icon-circle" />
        </section>

        <FaqRow />

        <IconCircleCyanSm className="icon-circle icon-circle--cyan" />
      </Wrapper>
    </Layout>
  );
};

export default WellnessCentrePage;

export const dataQuery = graphql`
  {
    content: prismicWellnessCentrePage {
      data {
        banner_heading {
          text
        }
        banner_description {
          html
        }
        banner_image_large {
          alt
          thumbnails {
            cropped {
              url
            }
          }
          gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
        }
        section_list {
          heading {
            text
          }
          description {
            html
          }
          featured_image {
            alt
            thumbnails {
              cropped {
                gatsbyImageData(layout: FULL_WIDTH)
              }
            }
          }
        }
        title_tag
        meta_description
      }
    }
  }
`;
